/* * {
  margin: 0;
  padding: 0;
  overflow: hidden;
} */

.main {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.overLay {
  background: rgb(0, 0, 0);
  width: 100%;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  opacity: 0.75;
}
.overLay2 {
  background: rgb(240, 102, 66);
  width: 100%;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  opacity: 0.1;
}

.video {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  object-fit: cover;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
/* .logoContainer {
  width: 272px;
  margin: -120px 0 0 0;
} */
.titlesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  /* width: 464px; */
}

.footer {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  opacity: 0.5;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  font: italic normal 600 14px/25px Nunito Sans;
}
.description {
  width: 464px;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0.17px;
  color: #ffffff;
}
.header {
  font: italic normal 600 30px/20px Nunito Sans;
  color: #ffffff;
  font-weight: 600;
  height: 45px;
  padding-top: 22px;
  margin-bottom: 8px;
}
.launchingTxtHolder {
  /* background: url("../../assets/highlight.svg") no-repeat; */
  height: 55px;
  width: 465px;
  margin-top: 10px;
  background-image: linear-gradient(90deg, #f06642 0%, #f09d51 100%),
    linear-gradient(90deg, #f06642 0%, #f09d51 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 17px; */
  padding-bottom: 26px;
  border-radius: 5px;
}
.launchingTxt {
  text-align: center;
  font: italic normal 900 45px/10px Nunito Sans;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 0;
  height: 100%;
  padding-top: 45px;
  
  opacity: 1;
}
.landingContent {
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media only screen and (max-height: 500px) {
  .logoContainer {
    /* width: 272px;
    height: 170px; */
    margin: -110px 0 0 0;
}
.landingContent {
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -40px;
}

  .launchingTxt {
    font: italic normal 900 20px/20px Nunito Sans;
    text-align: center;
    height: 100%;
    padding-top: 40px;
  }
  .launchingTxtHolder {
    width: 230px;
    padding-bottom: 10px;
    margin-bottom: 5px;

  }
  .logoContainer svg {
    width: 170px;
    height: 150px;
  }
  .header {
    font-size: 22px;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 0;
  }
  .description {
    width: 250px;
    font-size: 12px;
    text-align: center;
    font-weight: 300;
    letter-spacing: 0.17px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: #ffffff;
  }
  /* .footer {
    display: flex;
    position: absolute;
    bottom: 10px;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    opacity: 0.5;
    font-weight: 600;
    letter-spacing: 0px;
    color: #ffffff;
    font: italic normal 600 14px/25px Nunito Sans;
  } */
}
.space10 {
  margin: 0 5px;
}
.socialIconsHolder {
  display: flex;
  flex-direction: row;
  /* margin-bottom: 15px; */
}


@media only screen and (max-width: 470px) {
  .logoContainer {
    width: 272px;
    margin: -120px 0 0 0;
}
  .launchingTxt {
    font: italic normal 900 30px/20px Nunito Sans;
    text-align: center;
    height: 100%;
    padding-top: 40px;
  }
  .launchingTxtHolder {
    width: 310px;
    padding-bottom: 10px;
    margin-bottom: 5px;

  }
  .logoContainer svg {
    width: 270px;
    height: 270px;
  }
  .header {
    font-size: 22px;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 0;
  }
  .description {
    width: 250px;
    font-size: 12px;
    text-align: center;
    font-weight: 300;
    letter-spacing: 0.17px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: #ffffff;
  }
}
